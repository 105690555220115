import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list = _resolveComponent("list")!
  const _component_back = _resolveComponent("back")!
  const _component_next = _resolveComponent("next")!
  const _component_pause = _resolveComponent("pause")!
  const _component_play = _resolveComponent("play")!
  const _component_search = _resolveComponent("search")!
  const _component_loading = _resolveComponent("loading")!
  const _component_download = _resolveComponent("download")!
  const _component_favorite = _resolveComponent("favorite")!

  return (_ctx.name === 'list')
    ? (_openBlock(), _createBlock(_component_list, {
        key: 0,
        style: _normalizeStyle(_ctx.style),
        className: _ctx.className
      }, null, 8, ["style", "className"]))
    : (_ctx.name === 'back')
      ? (_openBlock(), _createBlock(_component_back, {
          key: 1,
          style: _normalizeStyle(_ctx.style),
          className: _ctx.className
        }, null, 8, ["style", "className"]))
      : (_ctx.name === 'next')
        ? (_openBlock(), _createBlock(_component_next, {
            key: 2,
            style: _normalizeStyle(_ctx.style),
            className: _ctx.className
          }, null, 8, ["style", "className"]))
        : (_ctx.name === 'pause')
          ? (_openBlock(), _createBlock(_component_pause, {
              key: 3,
              style: _normalizeStyle(_ctx.style),
              className: _ctx.className
            }, null, 8, ["style", "className"]))
          : (_ctx.name === 'play')
            ? (_openBlock(), _createBlock(_component_play, {
                key: 4,
                style: _normalizeStyle(_ctx.style),
                className: _ctx.className
              }, null, 8, ["style", "className"]))
            : (_ctx.name === 'search')
              ? (_openBlock(), _createBlock(_component_search, {
                  key: 5,
                  style: _normalizeStyle(_ctx.style),
                  className: _ctx.className
                }, null, 8, ["style", "className"]))
              : (_ctx.name === 'loading')
                ? (_openBlock(), _createBlock(_component_loading, {
                    key: 6,
                    style: _normalizeStyle(_ctx.style),
                    className: _ctx.className
                  }, null, 8, ["style", "className"]))
                : (_ctx.name === 'download')
                  ? (_openBlock(), _createBlock(_component_download, {
                      key: 7,
                      style: _normalizeStyle(_ctx.style),
                      className: _ctx.className
                    }, null, 8, ["style", "className"]))
                  : (_ctx.name === 'favorite')
                    ? (_openBlock(), _createBlock(_component_favorite, {
                        key: 8,
                        style: _normalizeStyle(_ctx.style),
                        className: _ctx.className
                      }, null, 8, ["style", "className"]))
                    : _createCommentVNode("", true)
}