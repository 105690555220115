import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77944d4b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "playlist__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "playlist__root",
    style: _normalizeStyle({ backgroundImage: `url(${_ctx.url})` })
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1)
  ], 4))
}