import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h2", {
    class: _normalizeClass({
            heading__root: true,
            heading__center: $props.center,
            heading__secondary: $props.secondary,
        })
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}