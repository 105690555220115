import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78341f3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"display":"flex","margin-top":"20px"} }
const _hoisted_3 = { style: {"margin-left":"10px"} }
const _hoisted_4 = { class: "channel__title" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { style: {"margin-top":"20px","border":"1px dashed black","padding":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_display_image = _resolveComponent("display-image")!
  const _component_expandable_text = _resolveComponent("expandable-text")!
  const _component_display_video = _resolveComponent("display-video")!
  const _component_fade_in = _resolveComponent("fade-in")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    name: "Channel",
    hidden: "",
    onPayload: _ctx.setPayload,
    onLeave: _ctx.leave
  }, {
    default: _withCtx(() => [
      _createVNode(_component_fade_in, null, {
        default: _withCtx(() => [
          (_ctx.channel && _ctx.preview)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_display_image, {
                  display: _ctx.channel.background,
                  height: "80px",
                  class: "channel__background"
                }, null, 8, ["display"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_display_image, {
                    display: _ctx.channel.avatar,
                    class: "channel__avatar"
                  }, null, 8, ["display"]),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.channel.title), 1),
                    (_ctx.preview.tag)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.preview.tag), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (_ctx.channel.description)
                  ? (_openBlock(), _createBlock(_component_expandable_text, {
                      key: 0,
                      text: _ctx.channel.description,
                      style: {"margin-top":"20px"}
                    }, null, 8, ["text"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videos?.items, (video) => {
                    return (_openBlock(), _createBlock(_component_display_video, {
                      key: video.code,
                      display: video.display,
                      code: video.code,
                      title: video.title,
                      active: video.code === _ctx.currentPlaying?.code,
                      onClick: ($event: any) => (_ctx.play(video)),
                      onPlay: ($event: any) => (_ctx.play(video))
                    }, null, 8, ["display", "code", "title", "active", "onClick", "onPlay"]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onPayload", "onLeave"]))
}