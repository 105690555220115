import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5234eaf1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "saved__nothing"
}
const _hoisted_2 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_display_playlist = _resolveComponent("display-playlist")!
  const _component_saved_playlist = _resolveComponent("saved-playlist")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    name: "Saved",
    title: "",
    shortcut: "",
    "shortcut-icon": "download"
  }, {
    default: _withCtx(() => [
      ($setup.saved?.length === 0 && ($setup.queueState.length === 0 || !$setup.isOnline))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Nothing saved yet "))
        : _createCommentVNode("", true),
      ($setup.queueState.length > 0 && $setup.isOnline)
        ? (_openBlock(), _createBlock(_component_display_playlist, {
            key: 1,
            title: "Downloads Queue",
            display: [{ url: '/queue1.jpeg', width: 0, height: 0 }],
            onClick: $setup.goToQueue
          }, null, 8, ["display", "onClick"]))
        : _createCommentVNode("", true),
      ($setup.saved?.length ?? 0 > 0)
        ? (_openBlock(), _createBlock(_component_display_playlist, {
            key: 2,
            title: "Everything",
            display: [{ url: '/saved-background.jpeg', width: 0, height: 0 }],
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.open('all')))
          }, null, 8, ["display"]))
        : _createCommentVNode("", true),
      ($setup.savedPlaylists)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.savedPlaylists, (playlist) => {
              return (_openBlock(), _createBlock(_component_saved_playlist, {
                key: playlist.list,
                title: playlist.title,
                thumbnail: playlist.thumbnail,
                onClick: ($event: any) => ($setup.open(playlist.list))
              }, null, 8, ["title", "thumbnail", "onClick"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      ($setup.others?.length ?? 0 > 0)
        ? (_openBlock(), _createBlock(_component_display_playlist, {
            key: 4,
            style: {"margin-top":"10px"},
            title: "Others",
            display: [{ url: '/others-background.jpeg', width: 0, height: 0 }],
            onClick: $setup.openOthers
          }, null, 8, ["display", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}