import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_display_video = _resolveComponent("display-video")!

  return (_openBlock(), _createBlock(_component_display_video, {
    code: _ctx.code,
    title: _ctx.title,
    display: _ctx.display,
    active: _ctx.active
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["code", "title", "display", "active"]))
}