import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "item__root",
    style: _normalizeStyle({
      left: `calc(50% + ${_ctx.position.x - 20}px)`,
      top: `calc(50% + ${_ctx.position.y - 20}px)`,
    })
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 4))
}