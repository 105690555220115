
import { CollectionSearchEntry, SearchEntry as SE } from "@dogonis/vtube-client";
import { defineComponent, PropType } from "vue";
import SearchEntry from "./SearchEntry.vue";

export default defineComponent({
    components: { SearchEntry },
    emits: {
        select: (entry: Exclude<SE, CollectionSearchEntry>) => !!entry,
    },  
    props: {
        collection: {
            type: Object as PropType<CollectionSearchEntry['value']>,
            required: true,
        },
    },
});
