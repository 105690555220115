
import { defineComponent, PropType } from "vue";
import { CollectionSearchEntry, SearchEntry } from "@dogonis/vtube-client";

import DisplayVideo from "@/components/DisplayVideo.vue";
import DisplayPlaylist from "@/components/DisplayPlaylist.vue";
import { useCurrentPlying } from "@/helpers/hooks/use-current-playing";

import DisplayChannel from "./DisplayChannel.vue";

export default defineComponent({
    components: { DisplayVideo, DisplayPlaylist, DisplayChannel },
    props: {
        entry: {
            type: Object as PropType<Exclude<SearchEntry, CollectionSearchEntry>>,
            required: true,
        },
    },
    emits: {
        select: (entry: Exclude<SearchEntry, CollectionSearchEntry>) => !!entry,
    },
    setup() {
        const currentPlaying = useCurrentPlying();

        return {
            currentPlaying,
        };
    },
});
