import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e767b91"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection__root" }
const _hoisted_2 = { class: "collection__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchEntry = _resolveComponent("SearchEntry")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.collection.title), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collection.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_SearchEntry, {
        key: index,
        entry: item,
        onSelect: _cache[0] || (_cache[0] = (entry) => {_ctx.$emit('select', entry)})
      }, null, 8, ["entry"]))
    }), 128))
  ]))
}