import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3137eaac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    style: _normalizeStyle(
      typeof _ctx.pageIndex === 'number'
        ? { zIndex: _ctx.pageIndex + 1, width: `calc(100% - ${_ctx.pageIndex * 2}px)` }
        : { width: '100%' }
    ),
    class: _normalizeClass({ root: true, visible: _ctx.isVisible, hidden: !_ctx.isVisible, [_ctx.className ?? '']: !!_ctx.className })
  }, [
    _createVNode(_component_Header),
    (typeof _ctx.title === 'string' || (!!_ctx.title && _ctx.name))
      ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(typeof _ctx.title === "string" ? _ctx.title : _ctx.name), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 6))
}