import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a33133be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "player-page__queue" }
const _hoisted_2 = { class: "player-page__placeholder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_player = _resolveComponent("player")!
  const _component_display_video = _resolveComponent("display-video")!
  const _component_saved_video = _resolveComponent("saved-video")!
  const _component_continue = _resolveComponent("continue")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    name: "Player",
    shortcut: "",
    shortcutIcon: "play",
    hidden: "",
    className: "player-page__root",
    onLeave: _ctx.leave,
    "auto-scroll": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_player, {
        margin: "10px 0 20px",
        code: _ctx.currentItem?.code,
        onEnded: _ctx.nextItem,
        saved: !!_ctx.currentItem?.saved
      }, null, 8, ["code", "onEnded", "saved"]),
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, " Empty Queue ", 512), [
          [_vShow, _ctx.queue.length === 0]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.queue, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.code,
            class: "player-page__item"
          }, [
            (!item.saved)
              ? (_openBlock(), _createBlock(_component_display_video, {
                  key: 0,
                  active: item.code === _ctx.currentItem.code,
                  onClick: ($event: any) => (_ctx.setCursor(index)),
                  onPlay: ($event: any) => (_ctx.setCursor(index)),
                  display: item.display,
                  code: item.code,
                  title: item.title
                }, null, 8, ["active", "onClick", "onPlay", "display", "code", "title"]))
              : (_openBlock(), _createBlock(_component_saved_video, {
                  key: 1,
                  active: item.code === _ctx.currentItem.code,
                  onClick: ($event: any) => (_ctx.setCursor(index)),
                  code: item.code,
                  title: item.title,
                  thumbnail: item.saved.thumbnail,
                  "disable-menu": ""
                }, null, 8, ["active", "onClick", "code", "title", "thumbnail"]))
          ]))
        }), 128)),
        (_ctx.limited)
          ? (_openBlock(), _createBlock(_component_continue, {
              key: 0,
              onContinue: _ctx.more
            }, null, 8, ["onContinue"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["onLeave"]))
}