<template>
    <div class="header__root">
        <span class="header__v">V</span>
        <span class="header__tube">tube</span>
    </div>
</template>

<style lang="scss" scoped>
.header__root {
    text-align: center;

    border-bottom: 1px solid #000;

    height: 82px;

    line-height: 80px;
}
.header__v {
    font-size: 97px;
}

.header__tube {
    margin-left: -11px;
}
</style>
