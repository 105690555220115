
import { 
    computed, defineComponent, ref, watch, watchEffect,
} from 'vue';

import Page from '@/Pages/components/Page.vue';
import { asyncComputed } from '@/helpers/hooks/asyncComputed';
import { useCurrentPlying } from '@/helpers/hooks/use-current-playing';
import { musicStorage } from '@/music-storage';
import DisplayImage from '@/components/DisplayImage.vue';
import { SavedVideo as SavedVideoType } from '@/music-storage/music-storage.model';
import SavedVideo from '@/components/SavedVideo.vue';
import Button from '@/components/Button.vue';
import { useStore } from '@/store';
import { QueueItem } from '@/store/modules/modules';
import { usePages } from '@/Pages/hooks/usePages';
import FadeIn from '@/components/FadeIn.vue';
import Icon from '@/components/Icon/Icon.vue';
import Continue from '@/components/Continue.vue';
import { ObjectURL } from '@/helpers/classes/object-url.class';
import { MusicStorage } from '@/music-storage/music-storage.class';
import { useDBObservable } from '@/helpers/hooks/use-db-observable';

export default defineComponent({
    components: { 
        Page,
        DisplayImage,
        SavedVideo,
        Button,
        FadeIn,
        Icon,
        Continue,
    },
    setup() {
        const store = useStore();
        const pages = usePages();
        const currentPlying = useCurrentPlying();
        const others = useDBObservable(musicStorage.others);

        const list = ref<string | null>(null);
        const displayLimit = ref(10);

        const [, playlist] = asyncComputed(async () => {
            if (!list.value) return "EXIT";

            if (list.value === "all" || list.value === MusicStorage.OTHERS_KEY) return "EXIT";

            return musicStorage.getPlaylist(list.value);
        });

        const isOthers = computed(() => list.value === MusicStorage.OTHERS_KEY);

        const currentPlaylist = computed(() => store.state.queue.currentPlaylist);

        const url = computed(() => {
            if (!playlist.value || list.value === "all") return null;
            return new ObjectURL(playlist.value.thumbnail);
        });

        const display = computed(() => {
            if (list.value === "all") {
                return [{
                    url: '/saved-background.jpeg',
                    width: 0,
                    height: 0,
                }];
            }
            if (list.value === MusicStorage.OTHERS_KEY) {
                return [{
                    url: '/others-background.jpeg',
                    width: 0,
                    height: 0,
                }];
            }
            if (!url.value) return null;
            return [{
                url: url.value.url,
                width: 0,
                height: 0,
            }];
        });

        const [, items] = asyncComputed(async () => {
            if (!list.value || !others.value) return "EXIT";

            if (list.value === "all") {
                return musicStorage.getAllSaved();
            }

            const audios = list.value === MusicStorage.OTHERS_KEY 
                ? others.value 
                : await musicStorage.getPlaylistAudios(list.value);
            
            const info = await Promise.all(
                audios.map((code) => musicStorage.getSavedInfo(code)),
            );

            return info.filter((a): a is SavedVideoType => !!a);
        });

        const displayItems = computed(() => items.value?.slice(0, displayLimit.value));

        watchEffect(() => {
            if (items.value?.length === 0) {
                if (currentPlaylist.value === list.value) {
                    store.commit("clear");
                }
                pages.goToPage("Saved");
            }
        });

        watch(url, (urlValue, _, onCleanup) => {
            if (!urlValue) return;
            onCleanup(() => {
                urlValue.destroy();
            });
        }, { immediate: true });

        return {
            list,
            playlist,
            display,
            items,
            displayItems,
            currentPlying,
            currentPlaylist,
            isOthers,
            setPayload(payload: string) {
                list.value = payload;
            },
            play() {
                if (!items.value || !list.value) return;
                store.commit("setPlaylist", {
                    list: list.value,
                    items: items.value.map<QueueItem>((item) => ({
                        code: item.code,
                        title: item.title,
                        display: [],
                        saved: {
                            thumbnail: item.thumbnail,
                        },
                    })),
                });
                pages.goToPage("Player");
            },
            shuffle() {
                if (!items.value || !list.value) return;
                store.commit("setPlaylist", {
                    list: list.value,
                    items: items.value.map<QueueItem>((item) => ({
                        code: item.code,
                        title: item.title,
                        display: [],
                        saved: {
                            thumbnail: item.thumbnail,
                        },
                    })),
                    shuffle: true,
                });
                pages.goToPage("Player");
            },
            playFromIndex(cursor: number) {
                if (!items.value || !list.value) return;
                store.commit("setPlaylist", {
                    list: list.value,
                    items: items.value.map<QueueItem>((item) => ({
                        code: item.code,
                        title: item.title,
                        display: [],
                        saved: {
                            thumbnail: item.thumbnail,
                        },
                    })),
                    cursor,
                });
            },
            remove() {
                if (!list.value) return;
                if (list.value === "all") {
                    musicStorage.deleteAllSaved();
                } else {
                    musicStorage.deletePlaylist(list.value);
                }
                if (currentPlaylist.value === list.value) {
                    store.commit("clear");
                }
                pages.goToPage("Saved");
            },
            deleteFromPlaylist(code: string) {
                if (!list.value) {
                    return;
                }
                if (currentPlying.value?.code === code) {
                    store.commit("next");
                }
                musicStorage.deleteFromPlaylist(list.value, code);
            },
            more() {
                displayLimit.value += 10;
            },
            leave() {
                list.value = null;
                displayLimit.value = 10;
            },
        };
    },
});
