import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_display_video = _resolveComponent("display-video")!

  return (_openBlock(), _createBlock(_component_display_video, {
    "disable-menu": "",
    active: _ctx.active,
    code: _ctx.code,
    title: _ctx.info?.title ?? '',
    display: _ctx.info?.display ?? []
  }, null, 8, ["active", "code", "title", "display"]))
}