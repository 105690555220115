
import {
  computed,
  defineComponent,
  inject,
  PropType,
  ref,
  watch,
} from "vue";

import { uuid } from "@/helpers/functions/uuid";
import Header from "@/components/Header.vue";
import { Name } from "@/components/Icon/Icon.model";

import { PagesAPIKey, PagesAPIDefault } from '../providers/Pages.provider';

export default defineComponent({
  components: { Header },
  props: {
    name: String,
    hidden: Boolean,
    shortcut: Boolean,
    shortcutIcon: String as PropType<Name>,
    title: [Boolean, String],
    className: String,
    shortcutIconLeftShift: Number,
    autoScroll: Boolean,
  },
  emits: ["payload", "enter", "leave"],
  setup(props, ctx) {
    const pagesAPI = inject(PagesAPIKey, PagesAPIDefault);
    
    const el = ref<HTMLDivElement>();
    
    const id = uuid();
    const pageIndex = pagesAPI.register(id, {
      shortcut: props.shortcut,
      shortcutIcon: props.shortcutIcon,
      hidden: props.hidden,
      name: props.name,
      leftShift: props.shortcutIconLeftShift,
    });

    const isActive = computed(() => pageIndex === pagesAPI.currentIndex.value);
    const isVisible = computed(() => {
      if (typeof pageIndex === "string") return pageIndex === pagesAPI.currentIndex.value;
      return pageIndex <= pagesAPI.currentIndex.value;
    });

    watch(
      [isActive, pagesAPI.pagePayload], 
      ([currentActive, currentPayload], [prevActive]) => {
        const isEnter = !prevActive && currentActive;
        const pagePayloadUpdate = prevActive && currentActive;
        const isLeave = prevActive && !currentActive;

        if (isEnter) {
          ctx.emit("enter");
          ctx.emit("payload", currentPayload);
        } else if (pagePayloadUpdate) {
          ctx.emit("payload", currentPayload);
          if (props.autoScroll && el.value) {
            el.value.scrollTo({
              top: 0,
            });
          }
        } else if (isLeave) {
          setTimeout(() => {
            ctx.emit("leave");
            if (props.autoScroll && el.value) {
              el.value.scrollTo({
                top: 0,
              });
            }
          }, 400);
        }
      },
    );

    return {
      el,
      isActive,
      pageIndex,
      isVisible,
    };
  },
});
