
import {
  computed,
  defineComponent,
  ref,
} from "vue";

import Page from "@/Pages/components/Page.vue";
import { useStore } from "@/store";
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import { usePages } from "@/Pages/hooks/usePages";
import { show } from "@/helpers/eruda";

export default defineComponent({
  components: { Page, Input, Button },
  setup() {
    const store = useStore();
    const pages = usePages();

    const counter = ref(0);
    const username = ref<string>("");
    const password = ref("");

    const isLoggedIn = computed(() => !!store.state.user.session);
    const userInfo = computed(() => store.state.user.info);

    return {
      isLoggedIn,
      username,
      password,
      userInfo,
      async submit() {
        const result = await store.dispatch("login", { username: username.value, password: password.value });
        if (result === "SUCCESS") {
          username.value = "";
          password.value = "";
          pages.goToPage("Music recommendations");
        }
      },
      reset() {
        store.commit("resetToken");
      },
      showConsole() {
        if (counter.value < 5) counter.value++;
        else show();
      },
    };
  },
});
