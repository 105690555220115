
import { CSSProperties, defineComponent, PropType } from "vue";

import { Name } from "./Icon.model";
import List from "./List.icon.vue";
import Play from "./Play.icon.vue";
import Next from "./Next.icon.vue";
import Back from "./Back.icon.vue";
import Pause from "./Pause.icon.vue";
import Search from "./Search.icon.vue";
import Loading from "./Loading.icon.vue";
import Download from "./Download.icon.vue";
import Favorite from "./Favorite.icon.vue";

export default defineComponent({
  components: {
    List, Play, Next, Back, Pause, Search, Loading, Download, Favorite,
  },
  props: {
    name: {
      type: String as PropType<Name>,
      required: true,
    },
    className: String,
    style: Object as PropType<CSSProperties>,
  },
});
