
import { computed, defineComponent } from "vue";
import { YTPlaylistWithID } from "@dogonis/vtube-client";
 
import Page from "@/Pages/components/Page.vue";
import { useStore } from "@/store";
import { usePages } from "@/Pages/hooks/usePages";
import { getMidItem } from "@/helpers/functions/getMidItem";
import { asyncComputed } from "@/helpers/hooks/asyncComputed";
import DisplayPlaylist from "@/components/DisplayPlaylist.vue";
import { useIsOnline } from "@/helpers/hooks/use-is-online";
import Heading from "@/components/Heading.vue";

export default defineComponent({
  components: { Page, DisplayPlaylist, Heading },
  setup() {
    const pages = usePages();
    const store = useStore();

    const isOnline = useIsOnline();

    const session = computed(() => store.state.user.session);

    const [, playlists] = asyncComputed(async () => {
      if (!session.value || !isOnline.value) return "EXIT";
      const data = await session.value.userPlaylists();
      return data;
    });

    return {
      isOnline,
      playlists,
      play: (list: YTPlaylistWithID) => {
        pages.goToPage("Playlist", list);
      },
      getMidItem,
    };
  },
});
