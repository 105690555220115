import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51973630"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dots__root" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(['dots__dot', _ctx.counter > 0 ? 'dots__dot--visible' : 'dots__dot--hidden'])
    }, ".", 2),
    _createElementVNode("span", {
      class: _normalizeClass(['dots__dot', _ctx.counter > 1 ? 'dots__dot--visible' : 'dots__dot--hidden'])
    }, ".", 2),
    _createElementVNode("span", {
      class: _normalizeClass(['dots__dot', _ctx.counter > 2 ? 'dots__dot--visible' : 'dots__dot--hidden'])
    }, ".", 2)
  ]))
}