
import { defineComponent, ref } from "vue";
import { ChannelPreview, YTPlaylistWithID, YTVideo } from "@dogonis/vtube-client";

import Page from "@/Pages/components/Page.vue";
import { asyncComputed } from "@/helpers/hooks/asyncComputed";
import DisplayImage from "@/components/DisplayImage.vue";
import FadeIn from "@/components/FadeIn.vue";
import ExpandableText from "@/components/ExpandableText.vue";
import DisplayVideo from "@/components/DisplayVideo.vue";
import DisplayPlaylist from "@/components/DisplayPlaylist.vue";
import { useCurrentPlying } from "@/helpers/hooks/use-current-playing";
import { useStore } from "@/store";
import { usePages } from "@/Pages/hooks/usePages";
import { vtube } from "@/helpers/vtube-client";

import DisplayChannel from "../SearchPage/components/DisplayChannel.vue";

export default defineComponent({
  components: {
    Page,
    DisplayImage,
    FadeIn,
    ExpandableText,
    DisplayVideo,
    DisplayPlaylist,
    DisplayChannel,
  },
  setup() {
    const currentPlaying = useCurrentPlying();
    const store = useStore();
    const pages = usePages();

    const channel = ref<null | ChannelPreview>(null);

    const [, info] = asyncComputed(() => {
      if (!channel.value) return "EXIT";
      return vtube.artist.get(channel.value.id);
    });

    return {
      channel,
      info,
      currentPlaying,
      init(data: ChannelPreview) {
        channel.value = data;
      },
      leave() {
        channel.value = null;
      },
      play(item: YTVideo) {
        store.commit('setQueue', [item]);
      },
      openPlaylist(item: YTPlaylistWithID) {
        pages.goToPage('Playlist', {
          list: item.list,
          title: item.title,
          display: item.display,
        });
      },
      openChannel(item: ChannelPreview) {
        pages.goToPage('Artist', item);
      },
    };
  },
});
