import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ed101ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu__container" }
const _hoisted_2 = { style: {"width":"100%","height":"100%","position":"relative"} }
const _hoisted_3 = {
  key: 0,
  class: "menu__label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_touch = _resolveDirective("touch")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.menuCoordinates)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "menu__root",
          style: _normalizeStyle({ left:`${_ctx.menuCoordinates.x - 30}px`, top: `${_ctx.menuCoordinates.y - 30}px` })
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "menu__center",
              style: _normalizeStyle({ transform: `translate(${_ctx.centerShift.x}px, ${_ctx.centerShift.y}px)` })
            }, [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.displayLabel)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.displayLabel), 1))
                  : _createCommentVNode("", true)
              ])
            ], 4),
            _renderSlot(_ctx.$slots, "items", {}, undefined, true)
          ])
        ], 4))
      : _createCommentVNode("", true)
  ])), [
    [_directive_touch, _ctx.initMenu, "hold"],
    [_directive_touch, _ctx.hideMenu, "release"],
    [
      _directive_touch,
      _ctx.touchMove,
      "drag",
      { once: true }
    ]
  ])
}