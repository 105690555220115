
import { YTImage } from "@dogonis/vtube-client";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
} from "vue";

export default defineComponent({
  props: {
    display: {
      type: Array as PropType<YTImage[]>,
      required: true,
    },
    height: String,
    width: String,
    margin: String,
  },
  setup(props) {
    const { display } = toRefs(props);
    const el = ref<HTMLDivElement>();
    const url = computed<string | undefined>(() => {
      if (!el.value) return undefined;

      const rect = el.value.getBoundingClientRect();
      
      let currentDiff = Infinity;
      let selected: YTImage | undefined;

      for (const image of display.value) {
        const diff = Math.abs(rect.width - image.width);

        if (diff < currentDiff) {
          selected = image;
          currentDiff = diff;
        }
      }
      return selected?.url;
    });

    return {
      el,
      url,
    };
  },
});
