
import {
    computed, defineComponent, ref, toRefs,
} from "vue";
import ClickAwayListener from "./ClickAwayListener.vue";

export default defineComponent({
  components: { ClickAwayListener },
    props: {
        text: {
            type: String,
            required: true,
        },
        limit: {
            type: Number,
            default: 100,
        },
    },
    setup(props) {
        const { limit, text } = toRefs(props);

        const expanded = ref(false);

        const short = computed(() => text.value.length > limit.value ? text.value.slice(0, limit.value) + '...' : text.value);

        const expandable = computed(() => text.value.length > limit.value);

        return {
            expandable,
            short,
            expanded,
        };
    },
});
