
import { computed, ref, watch } from 'vue';
import { YTPlaylistWithID } from '@dogonis/vtube-client';

import { asyncComputed } from '@/helpers/hooks/asyncComputed';
import Page from '@/Pages/components/Page.vue';
import DisplayVideo from '@/components/DisplayVideo.vue';
import DisplayImage from '@/components/DisplayImage.vue';
import Button from '@/components/Button.vue';
import FadeIn from '@/components/FadeIn.vue';
import { useStore } from '@/store';
import { usePages } from '@/Pages/hooks/usePages';
import { QueueItem } from '@/store/modules/modules';
import Icon from '@/components/Icon/Icon.vue';
import { musicStorage } from '@/music-storage';
import { useDBObservable } from '@/helpers/hooks/use-db-observable';
import { vtube } from '@/helpers/vtube-client';

export default {
    components: { 
        Page, 
        DisplayVideo, 
        DisplayImage, 
        Button, 
        FadeIn,
        Icon,
    },
    setup() {
        const store = useStore();
        const pages = usePages();
        const savedPlaylists = useDBObservable(musicStorage.savedPlaylists);

        const playlist = ref<YTPlaylistWithID | null>(null);
        
        const playRequest = ref<null | { type: "P" | "S", i: number | null }>(null);

        const isDownloaded = computed(
            () => !!savedPlaylists.value?.find((item) => item.list === playlist.value?.list),
        );

        const isCurrentPlaylist = computed(
            () => store.state.queue.currentPlaylist === playlist.value?.list,
        );
        const currentVideo = computed<QueueItem | null>(
            () => store.state.queue.items[store.state.queue.cursor] ?? null,
        );
    
        const [, items] = asyncComputed(async () => {
            if (!playlist.value) return "EXIT";
            const { list } = await vtube.getPlaylist(playlist.value.list);
            return list.items;
        });

        watch([playRequest, items], ([request, list]) => {
            if (!request || !list) return;

            if (request.type === "S") {
                store.commit("setPlaylist", {
                    items: list,
                    list: playlist.value?.list,
                    shuffle: true,
                });
                pages.goToPage("Player");
            } else if (request.i === null) {
                store.commit("setPlaylist", {
                    items: list,
                    list: playlist.value?.list,
                });
                pages.goToPage("Player");
            } else {
                store.commit("setPlaylist", {
                    items: list,
                    cursor: request.i,
                    list: playlist.value?.list,
                });
            }
        });

        return {
            playlist,
            items,
            currentVideo,
            isCurrentPlaylist,
            isDownloaded,
            save() {
                if (!playlist.value?.list) return;

                musicStorage.savePlaylist(playlist.value.list);
            },
            setPayload(payload: YTPlaylistWithID | null) {
                playlist.value = payload;
            },
            play() {
                playRequest.value = {
                    i: null,
                    type: "P",
                };
            },
            shuffle() {
                playRequest.value = {
                    type: "S",
                    i: null,
                };
            },
            cleanup() {
                playRequest.value = null;
            },
            playFromPosition(index: number) {
                playRequest.value = {
                    i: index,
                    type: "P",
                };
            },
        };
    },
};

