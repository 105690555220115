import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c73c63f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expandable__root" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_click_away_listener = _resolveComponent("click-away-listener")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.short) + " ", 1),
    (_ctx.expandable)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          style: {"color":"rgba(62, 62, 62, .5)"},
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.expanded = true), ["stop"]))
        }, " more "))
      : _createCommentVNode("", true),
    (_ctx.expandable)
      ? (_openBlock(), _createBlock(_component_click_away_listener, {
          key: 1,
          class: _normalizeClass({
                expandable__full: true,
                'expandable__full--visible': _ctx.expanded,
                'expandable__full--hidden': !_ctx.expanded,
            }),
          onClickAway: _cache[2] || (_cache[2] = ($event: any) => (_ctx.expanded = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
            _createElementVNode("div", {
              style: {"color":"rgba(62, 62, 62, .5)","margin-top":"5px","text-align":"center"},
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expanded = false))
            }, " Hide ")
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true)
  ]))
}