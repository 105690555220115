
import {
 PropType, toRef, ref, watchEffect, computed, defineComponent, 
} from "vue";
import { YTImage } from "@dogonis/vtube-client";

import { useSaved } from "@/music-storage/use-saved";
import { musicStorage } from "@/music-storage";
import { useIsDownloading } from "@/helpers/hooks/use-is-downloading";

import DisplayImage from './DisplayImage.vue';
import Button from './Button.vue';

export default defineComponent({
    components: { DisplayImage, Button },
    props: {
        display: {
            type: Array as PropType<YTImage[]>,
            required: true,
        },
        code: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        active: Boolean,
        disableMenu: Boolean,
    },
    emits: ['play'],
    setup(props, { emit }) {
        const code = toRef(props, 'code');

        const menuState = ref(false);

        const isDownloading = useIsDownloading(code);

        const elRef = ref<HTMLDivElement | null>(null);
        
        const saved = useSaved();
        
        const isDownloaded = computed(() => saved.value?.find((item) => item.code === code.value));

        watchEffect((onCleanup) => {
            if (!elRef.value) return;
            const el = elRef.value;
            const handler = (e: MouseEvent) => {
                if (!el.contains(e.target as any)) {
                    if (menuState.value) {
                        menuState.value = false;
                    }
                }
            };

            document.addEventListener('click', handler);
            onCleanup(() => {
                document.removeEventListener('click', handler);
            });
        });

        return {
            menuState,
            elRef,
            isDownloaded,
            isDownloading,
            openMenu() {
                menuState.value = !menuState.value;
            },
            play(e: Event) {
                emit('play', e);
                menuState.value = false;
            },
            async save() {
                musicStorage.saveToOthers(code.value);
            },
        };
    },
});
