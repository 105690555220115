
import {
  computed,
  defineComponent,
  ref,
  toRefs,
  watch,
  watchEffect,
} from 'vue';
import { YTVideo } from '@dogonis/vtube-client';

import { env } from '@/env';
import { getMidItem } from '@/helpers/functions/getMidItem';
import { asyncComputed } from '@/helpers/hooks/asyncComputed';
import Icon from '@/components/Icon/Icon.vue';
import { musicStorage } from '@/music-storage';
import DisplayImage from '@/components/DisplayImage.vue';
import { ObjectURL } from '@/helpers/classes/object-url.class';
import { vtube } from '@/helpers/vtube-client';

export default defineComponent({
  components: { Icon, DisplayImage },
  props: {
    code: String,
    margin: String,
    saved: Boolean,
  },
  setup(props, { emit }) {
    const { code, saved } = toRefs(props);

    const doubleClickThreshold = 220;
    let prevTimestamp = 0;
    let pauseRequest: null | number = null;

    const isPlaying = ref(true);
    const audio = ref<HTMLAudioElement>();
    const progress = ref(0);
    const lastProgressChange = ref<null | number>(null);
    const playTouched = ref(false);
    const volume = ref<number>(1);
    
    const formattedLastProgress = computed(() => {
      if (!lastProgressChange.value) return null;

      const h = Math.floor(lastProgressChange.value / 3600);
      const m = Math.floor(((lastProgressChange.value % 3600) / 60));
      const s = Math.floor(lastProgressChange.value % 60);

      const formatted = [h, m, s].filter((item) => item >= 1).map((item, index) => {
        if (index === 0) return item;
        if ((item + "").length === 2) return item;
        return "0" + item;
      }).join(":");

      return formatted.length > 2 ? formatted : formatted + " s";
    });

    const [, media] = asyncComputed<{
      src: string, 
      mime: string, 
      obj: ObjectURL | null
    }>((status) => {
      const videoID = code.value;
      if (!videoID) return "EXIT";

      if (saved.value) {
        return musicStorage.getAudio(videoID)
          .then((blob) => {
            if (!blob || !status.isActive) return "EXIT";
            const url = new ObjectURL(blob);
            return {
              src: url.url,
              mime: blob.type,
              obj: url,
            };
          });
      }

      return vtube.video.formats(videoID, "audio")
        .then((formats) => {
          if (!status.isActive) return "EXIT";

          const midFormat = getMidItem(formats);

          return {
            src: vtube.video.srcURL(videoID, midFormat.itag),
            mime: midFormat.mime,
            obj: null,
          };
        });
    });

    watch(media, (mediaValue, _, onCleanup) => {
      const obj = mediaValue?.obj;
      if (obj) {
        onCleanup(() => {
          obj.destroy();
        });
      }
    });

    const [, info] = asyncComputed<YTVideo & { obj: ObjectURL | null }>(async () => {
      if (!code.value) return "EXIT";
      if (saved.value) {
        const savedInfo = await musicStorage.getSavedInfo(code.value);

        if (!savedInfo) return "EXIT";
        const obj = new ObjectURL(savedInfo.thumbnail);
        return {
          title: savedInfo.title,
          display: [{
            url: obj.url,
            width: 0,
            height: 0,
          }],
          code: code.value,
          obj,
        };
      }
      return {
        ...(await vtube.video.info(code.value)),
        obj: null,
      };
    });

    watchEffect(() => {
      if (!info.value) {
        navigator.mediaSession.metadata = null;
        return;
      }
      navigator.mediaSession.metadata = new MediaMetadata({
        title: info.value.title,
        artwork: info.value.display.map((item) => ({
          src: item.url,
        })),
      });
    });

    watch(info, (infoValue, _, onCleanup) => {
      const obj = infoValue?.obj;
      if (obj) {
        onCleanup(() => {
          obj.destroy();
        });
      }
    });

    watchEffect(() => {
      if (!audio.value) return;
      if (isPlaying.value) {
        navigator.mediaSession.playbackState = "playing";
        audio.value.play();
      } else {
        navigator.mediaSession.playbackState = "paused";
        audio.value.pause();
      } 
    });

    watchEffect(() => {
      navigator.mediaSession.setActionHandler("play", () => {
        isPlaying.value = true;
      });
      navigator.mediaSession.setActionHandler("pause", () => {
        isPlaying.value = false;
      });
      navigator.mediaSession.setActionHandler("seekforward", () => {
        if (!audio.value) return;
        audio.value.currentTime += 10;
      });
      navigator.mediaSession.setActionHandler("seekbackward", () => {
        if (!audio.value) return;
        audio.value.currentTime -= 10;
      });
    });

    watch([volume, audio], ([volumeValue, el]) => {
      if (!el) return;
      el.volume = volumeValue;
    });

    watch(isPlaying, (value) => {
      if (!value) playTouched.value = true;
    });

    // reset and autoplay for dynamic video code
    watch([media, audio], () => {
      if (!audio.value || !media.value) return;
      isPlaying.value = true;
      playTouched.value = false;
      progress.value = 0;
      lastProgressChange.value = null;
    });

    return {
      media,
      audio,
      isPlaying,
      info,
      getMidItem,
      progress,
      formattedLastProgress,
      playTouched,
      volume,

      handleProgress: (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!audio.value) return;

        const lineWidth = +getComputedStyle(e.target as HTMLDivElement).width.slice(0, -2);
        const nextProgress = e.offsetX / lineWidth;
        const nextTime = nextProgress * audio.value.duration;
        audio.value.currentTime = nextTime;
        lastProgressChange.value = nextTime;
      },
      interact: (e: MouseEvent) => {
        if (!audio.value) return;
        const diff = e.timeStamp - prevTimestamp;
        prevTimestamp = e.timeStamp;
        if (diff < doubleClickThreshold) {
          if (pauseRequest) clearTimeout(pauseRequest);

          const width = e.offsetX
            / +getComputedStyle(e.target as HTMLDivElement).width.slice(0, -2);

          if (width > 0.5) {
            audio.value.currentTime += 10;
          } else {
            audio.value.currentTime -= 10;
          }
          lastProgressChange.value = audio.value.currentTime;
        } else {
          pauseRequest = setTimeout(() => {
            isPlaying.value = !isPlaying.value;
          }, doubleClickThreshold);
        }
      },
      ended: () => {
        emit('ended');
      },
    };
  },
});
