import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    style: _normalizeStyle({
      minWidth: _ctx.width,
      maxWidth: _ctx.width,
      height: _ctx.height,
      backgroundImage: _ctx.url && `url(${_ctx.url})`,
      margin: _ctx.margin,
    }),
    class: "display-image__root"
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 4))
}