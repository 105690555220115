import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Collection = _resolveComponent("Collection")!
  const _component_DisplayEntry = _resolveComponent("DisplayEntry")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entries, (entry, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        style: {"margin-bottom":"10px"}
      }, [
        (entry.type === 'COLLECTION')
          ? (_openBlock(), _createBlock(_component_Collection, {
              key: 0,
              collection: entry.value,
              onSelect: (entry) => _ctx.$emit('select', entry)
            }, null, 8, ["collection", "onSelect"]))
          : (_openBlock(), _createBlock(_component_DisplayEntry, {
              key: 1,
              entry: entry,
              onSelect: (entry) => _ctx.$emit('select', entry)
            }, null, 8, ["entry", "onSelect"]))
      ]))
    }), 128))
  ]))
}