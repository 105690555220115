
import { defineComponent, ref } from "vue";
import { ChannelPreview, YTVideo } from "@dogonis/vtube-client";

import { asyncComputed } from "@/helpers/hooks/asyncComputed";
import Page from "@/Pages/components/Page.vue";
import ExpandableText from "@/components/ExpandableText.vue";
import DisplayImage from "@/components/DisplayImage.vue";
import FadeIn from "@/components/FadeIn.vue";
import DisplayVideo from "@/components/DisplayVideo.vue";
import { useStore } from "@/store";
import { useCurrentPlying } from "@/helpers/hooks/use-current-playing";
import { vtube } from "@/helpers/vtube-client";

export default defineComponent({
    components: {
        Page,
        ExpandableText,
        DisplayImage,
        FadeIn,
        DisplayVideo,
    },
    setup() {
        const store = useStore();
        const currentPlaying = useCurrentPlying();

        const preview = ref<ChannelPreview | null>(null);

        const [, channel] = asyncComputed(() => {
            if (!preview.value) return "EXIT";
            return vtube.channel.info(preview.value.id);
        });

        const [, videos] = asyncComputed(() => {
            if (!preview.value) return "EXIT";

            return vtube.channel.videos(preview.value.id);
        });

        return {
            preview,
            channel,
            videos,
            currentPlaying,
            play(video: YTVideo) {
                store.commit("setQueue", [video]);
            },
            setPayload(data: ChannelPreview) {
                preview.value = data;
            },
            leave() {
                preview.value = null;
            },
        };
    },
});
