
import { defineComponent, PropType } from 'vue';
import { YTImage } from '@dogonis/vtube-client';

import { getMidItem } from '@/helpers/functions/getMidItem';

export default defineComponent({
    props: {
        title: {
            required: true,
            type: String,
        },
        display: {
            required: true,
            type: Array as PropType<YTImage[]>,
        },
    },
    computed: {
        url() {
            return getMidItem(this.display).url;
        },
    },
});
