
import {
  computed,
  defineComponent,
  ref,
  watchEffect,
} from "vue";

import Page from "@/Pages/components/Page.vue";
import { useStore } from "@/store";
import DisplayVideo from "@/components/DisplayVideo.vue";
import SavedVideo from "@/components/SavedVideo.vue";
import Continue from "@/components/Continue.vue";

import Player from "./Player.vue";

export default defineComponent({
  components: {
    Page,
    Player,
    DisplayVideo,
    SavedVideo,
    Continue, 
},
  setup() {
    const store = useStore();

    const currentItem = computed(() => store.state.queue.items[store.state.queue.cursor]);
    const limited = ref(true);

    const queue = computed(() => limited.value 
      ? store.state.queue.items.slice(0, 30) 
      : store.state.queue.items);

    watchEffect(() => {
      navigator.mediaSession.setActionHandler("nexttrack", () => {
        store.commit("next");
      });
      navigator.mediaSession.setActionHandler("previoustrack", () => {
        store.commit("prev");
      });
      navigator.mediaSession.setActionHandler('stop', () => {
        store.commit("clear");
      });
    });

    return {
      currentItem,
      queue,
      limited,
      more() {
        limited.value = false;
      },
      leave() {
        limited.value = true;
      },
      setCursor: (index: number) => store.commit('setCursor', index),
      nextItem: () => store.commit('next'),
    };
  },
});
