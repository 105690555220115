import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6971672f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "display-channel__root" }
const _hoisted_2 = { class: "display-channel__text" }
const _hoisted_3 = { class: "display-channel__username" }
const _hoisted_4 = {
  key: 0,
  class: "display-channel__tag"
}
const _hoisted_5 = {
  key: 1,
  class: "display-channel__description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_display_image = _resolveComponent("display-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_display_image, {
      class: "display-channel__avatar",
      display: _ctx.display
    }, null, 8, ["display"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.username), 1),
      (_ctx.tag)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.tag), 1))
        : _createCommentVNode("", true),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.description), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}