
import {
  defineComponent, onMounted, onUnmounted, ref,
} from "vue";

export default defineComponent({
  setup() {
    const counter = ref(0);
    const interval = ref<null | number>(null);

    onMounted(() => {
      interval.value = setInterval(() => {
        counter.value = (counter.value + 1) % 4;
      }, 300);
    });
    
    onUnmounted(() => {
      if (interval.value) {
        clearInterval(interval.value);
      }
    });

    return {
      counter,
    };
  },
});
