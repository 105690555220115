import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-feb8411e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "input__title"
}
const _hoisted_2 = { class: "input__container" }
const _hoisted_3 = ["value", "type", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        class: _normalizeClass({
                    'input': true,
                    'input--active': _ctx.state || !_ctx.value,
                    'input--default': !_ctx.state,
                }),
        value: _ctx.value,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args))),
        contenteditable: "",
        spellcheck: "false",
        autocapitalize: "none",
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.state = true)),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.state = false)),
        type: _ctx.password ? 'password' : 'text',
        placeholder: _ctx.placeholder
      }, null, 42, _hoisted_3)
    ])
  ]))
}