
import { defineComponent, PropType } from "vue";
import { YTImage } from "@dogonis/vtube-client";

import DisplayImage from "@/components/DisplayImage.vue";

export default defineComponent({
  components: { DisplayImage },
    props: {
        display: {
            type: Array as PropType<YTImage[]>,
            required: true,
        },
        username: {
            type: String,
            required: true,
        },
        description: String,
        tag: String,
    },
});
