import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_display_video = _resolveComponent("display-video")!
  const _component_display_playlist = _resolveComponent("display-playlist")!
  const _component_display_channel = _resolveComponent("display-channel")!

  return (_ctx.entry.type === 'VIDEO')
    ? (_openBlock(), _createBlock(_component_display_video, {
        key: 0,
        code: _ctx.entry.value.code,
        title: _ctx.entry.value.title,
        active: _ctx.entry.value.code === _ctx.currentPlaying?.code,
        display: _ctx.entry.value.display,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', _ctx.entry))),
        onPlay: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('select', _ctx.entry)))
      }, null, 8, ["code", "title", "active", "display"]))
    : (_ctx.entry.type === 'PLAYLIST' || _ctx.entry.type === 'DYNAMIC_PLAYLIST')
      ? (_openBlock(), _createBlock(_component_display_playlist, {
          key: 1,
          display: _ctx.entry.value.display,
          title: _ctx.entry.value.title,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('select', _ctx.entry)))
        }, null, 8, ["display", "title"]))
      : (_ctx.entry.type === 'CHANNEL')
        ? (_openBlock(), _createBlock(_component_display_channel, {
            key: 2,
            username: _ctx.entry.value.title,
            display: _ctx.entry.value.display,
            description: _ctx.entry.value.description ?? undefined,
            tag: _ctx.entry.value.tag ?? undefined,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('select', _ctx.entry)))
          }, null, 8, ["username", "display", "description", "tag"]))
        : _createCommentVNode("", true)
}