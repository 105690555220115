
import { useSaved } from '@/music-storage/use-saved';
import Page from '@/Pages/components/Page.vue';
import { usePages } from '@/Pages/hooks/usePages';
import { musicStorage } from '@/music-storage';
import { useDBObservable } from '@/helpers/hooks/use-db-observable';
import SavedPlaylist from '@/components/SavedPlaylist.vue';
import DisplayPlaylist from '@/components/DisplayPlaylist.vue';
import { useIsOnline } from '@/helpers/hooks/use-is-online';
import { useDownloadsQueue } from '@/helpers/hooks/use-downloads-queue';
import { MusicStorage } from '@/music-storage/music-storage.class';

export default {
    components: { Page, SavedPlaylist, DisplayPlaylist },
    setup() {
        const pages = usePages();
        const saved = useSaved();
        const savedPlaylists = useDBObservable(musicStorage.savedPlaylists);
        const others = useDBObservable(musicStorage.others);
        const isOnline = useIsOnline();

        const queueState = useDownloadsQueue();

        return {
            others,
            queueState,
            saved,
            savedPlaylists,
            isOnline,
            open(list: string) {
                pages.goToPage("SavedPlaylist", list);
            },
            goToQueue() {
                pages.goToPage("Queue");
            },
            openOthers() {
                pages.goToPage("SavedPlaylist", MusicStorage.OTHERS_KEY);
            },
        };
    },
};
