
import {
  defineComponent,
  ref,
  watch,
  watchEffect,
} from "vue";
import { CollectionSearchEntry, SearchEntry } from "@dogonis/vtube-client";

import Page from "@/Pages/components/Page.vue";
import Input from "@/components/Input.vue";
import { debounce } from "@/helpers/functions/debounce";
import { asyncComputed } from "@/helpers/hooks/asyncComputed";
import FadeIn from "@/components/FadeIn.vue";
import Button from "@/components/Button.vue";
import { useStore } from "@/store";
import { usePages } from "@/Pages/hooks/usePages";
import { vtube } from "@/helpers/vtube-client";

import Dots from "./components/Dots.vue";
import Results from "./components/Results.vue";

export default defineComponent({
  components: {
    Page,
    Input,
    FadeIn,
    Dots,
    Button,
    Results,
  },
  setup() {
    const store = useStore();
    const pages = usePages();

    const shows = ref<"yt" | "m">("m");

    const input = ref("");
    const query = ref("");

    const [, results] = asyncComputed(async () => {
      if (!query.value) {
        return {
          music: [],
          youtube: [],
        };
      }

      const [youtube, music] = await Promise.all([
        vtube.search.queryYoutube(query.value),
        vtube.search.queryMusic(query.value),
      ]);

      return {
        youtube, music,
      };
    });

    const search = debounce(async (text: string) => {
      query.value = text.trim();
    }, 500);

    watch(input, (value) => {
      search(value);
    });

    watchEffect(() => {
      if (
        !results.value 
        || (results.value.music.length === 0 && results.value.youtube.length === 0)
      ) return;
      
      if (results.value.music.length === 0) {
        shows.value = 'yt';
      } else if (results.value.youtube.length === 0) {
        shows.value = 'm';
      }
    });

    return {
      input,
      results,
      shows,
      action(entry: Exclude<SearchEntry, CollectionSearchEntry>) {
        if (entry.type === "VIDEO") {
          store.commit('setQueue', [{
            title: entry.value.title,
            display: entry.value.display,
            code: entry.value.code,
          }]);
        } else if (entry.type === "PLAYLIST") {
          pages.goToPage('Playlist', {
            list: entry.value.list,
            title: entry.value.title,
            display: entry.value.display,
          });
        } else if (entry.type === "DYNAMIC_PLAYLIST") {
          store.dispatch('playDynamicPlaylist', {
            list: entry.value.list,
            code: entry.value.code,
          });
          pages.goToPage("Player");
        } else if (entry.type === "CHANNEL") {
          if (shows.value === 'm') {
            pages.goToPage("Artist", entry.value);
          } else {
            pages.goToPage("Channel", entry.value);
          }
        }
      },
    };
  },
});
