
import { defineComponent, PropType } from 'vue';
import { CollectionSearchEntry, SearchEntry } from '@dogonis/vtube-client';

import DisplayEntry from "./SearchEntry.vue";
import Collection from "./Collection.vue";

export default defineComponent({
    components: { DisplayEntry, Collection },
    props: {
        entries: {
            type: Array as PropType<SearchEntry[]>,
            required: true,
        },
    },
    emits: {
        select: (data: Exclude<SearchEntry, CollectionSearchEntry>) => !!data,
    },
});
