
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        title: String,
        value: String,
        password: Boolean,
        placeholder: String,
    },
    emits: ["update:value"],
    setup(_, ctx) {
        const state = ref<boolean>(false);

        return {
            state,
            update(e: Event) {
                ctx.emit("update:value", (e.target as HTMLInputElement).value);
            },
        };
    },
});
