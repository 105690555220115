
import { defineComponent, watchEffect } from 'vue';
import PlayerPage from './content/PlayerPage/PlayerPage.vue';
import ProfilePage from './content/ProfilePage.vue';
import MusicRecommendationsPage from './content/MusicRecommendationsPage.vue';
import SearchPage from './content/SearchPage/SearchPage.vue';
import PlaylistsPage from './content/PlaylistsPage.vue';
import Pages from './Pages/Pages.vue';
import PlaylistPage from './content/PlaylistPage.vue';
import SavedPage from './content/SavedPage.vue';
import SavedPlaylistPage from './content/SavedPlaylistPage.vue';
import QueuePage from './content/QueuePage.vue';
import ArtistPage from './content/ArtistPage/ArtistPage.vue';
import { musicStorage } from './music-storage';
import { useIsOnline } from './helpers/hooks/use-is-online';
import ChannelPage from './content/ChannelPage/ChannelPage.vue';

export default defineComponent({
  components: {
    Pages,
    PlayerPage,
    MusicRecommendationsPage,
    ProfilePage,
    SearchPage,
    PlaylistsPage,
    PlaylistPage,
    SavedPage,
    SavedPlaylistPage,
    QueuePage,
    ArtistPage,
    ChannelPage,
  },
  name: 'App',
  setup() {
    const isOnline = useIsOnline();

    watchEffect(() => {
      if (isOnline.value) {
        musicStorage.updateSavedPlaylists();
      }
    });
  },
});
