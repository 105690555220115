
import { defineComponent, PropType } from "vue";
import Page from "./Page.vue";

export default defineComponent({
  components: { Page },
  props: {
    items: {
      type: Array as PropType<Array<{
        label: string,
        index: number,
      }>>,
      default: () => [],
    },
  },
  methods: {
    handleSelect(index: number) {
      this.$emit("select", index);
    },
  },
});
