import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8795d08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "artist__display" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "artist__category__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_display_image = _resolveComponent("display-image")!
  const _component_expandable_text = _resolveComponent("expandable-text")!
  const _component_display_video = _resolveComponent("display-video")!
  const _component_display_playlist = _resolveComponent("display-playlist")!
  const _component_display_channel = _resolveComponent("display-channel")!
  const _component_fade_in = _resolveComponent("fade-in")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    name: "Artist",
    hidden: "",
    "auto-scroll": "",
    onPayload: _ctx.init,
    onLeave: _ctx.leave,
    title: _ctx.channel?.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.channel)
          ? (_openBlock(), _createBlock(_component_display_image, {
              key: 0,
              display: _ctx.channel.display,
              class: "artist__image"
            }, null, 8, ["display"]))
          : _createCommentVNode("", true),
        (_ctx.info)
          ? (_openBlock(), _createBlock(_component_display_image, {
              key: 1,
              display: _ctx.info.background,
              class: "artist__image artist__image--in"
            }, null, 8, ["display"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_fade_in, null, {
        default: _withCtx(() => [
          (_ctx.info)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.info.description)
                  ? (_openBlock(), _createBlock(_component_expandable_text, {
                      key: 0,
                      text: _ctx.info.description
                    }, null, 8, ["text"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.categories, (category, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "artist__category"
                  }, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(category.title), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.items, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "artist__category__item"
                      }, [
                        ('code' in item)
                          ? (_openBlock(), _createBlock(_component_display_video, {
                              key: 0,
                              code: item.code,
                              title: item.title,
                              display: item.display,
                              onClick: ($event: any) => (_ctx.play(item)),
                              onPlay: ($event: any) => (_ctx.play(item)),
                              active: _ctx.currentPlaying?.code === item.code
                            }, null, 8, ["code", "title", "display", "onClick", "onPlay", "active"]))
                          : ('list' in item)
                            ? (_openBlock(), _createBlock(_component_display_playlist, {
                                key: 1,
                                display: item.display,
                                title: item.title,
                                onClick: ($event: any) => (_ctx.openPlaylist(item))
                              }, null, 8, ["display", "title", "onClick"]))
                            : ('id' in item)
                              ? (_openBlock(), _createBlock(_component_display_channel, {
                                  key: 2,
                                  display: item.display,
                                  username: item.title,
                                  onClick: ($event: any) => (_ctx.openChannel(item))
                                }, null, 8, ["display", "username", "onClick"]))
                              : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onPayload", "onLeave", "title"]))
}