import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a3e964e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app__container" }
const _hoisted_2 = { class: "app__pages" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_music_recommendations_page = _resolveComponent("music-recommendations-page")!
  const _component_playlists_page = _resolveComponent("playlists-page")!
  const _component_search_page = _resolveComponent("search-page")!
  const _component_player_page = _resolveComponent("player-page")!
  const _component_saved_page = _resolveComponent("saved-page")!
  const _component_profile_page = _resolveComponent("profile-page")!
  const _component_playlist_page = _resolveComponent("playlist-page")!
  const _component_saved_playlist_page = _resolveComponent("saved-playlist-page")!
  const _component_queue_page = _resolveComponent("queue-page")!
  const _component_artist_page = _resolveComponent("artist-page")!
  const _component_channel_page = _resolveComponent("channel-page")!
  const _component_pages = _resolveComponent("pages")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_pages, null, {
        default: _withCtx(() => [
          _createVNode(_component_music_recommendations_page),
          _createVNode(_component_playlists_page),
          _createVNode(_component_search_page),
          _createVNode(_component_player_page),
          _createVNode(_component_saved_page),
          _createVNode(_component_profile_page),
          _createVNode(_component_playlist_page),
          _createVNode(_component_saved_playlist_page),
          _createVNode(_component_queue_page),
          _createVNode(_component_artist_page),
          _createVNode(_component_channel_page)
        ]),
        _: 1
      })
    ])
  ]))
}