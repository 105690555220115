
import {
  computed, defineComponent,
} from "vue";
import { Recommendation } from "@dogonis/vtube-client";

import Page from "@/Pages/components/Page.vue";
import { getMidItem } from "@/helpers/functions/getMidItem";
import { useStore } from "@/store";
import { asyncComputed } from "@/helpers/hooks/asyncComputed";
import DisplayImage from "@/components/DisplayImage.vue";
import { usePages } from "@/Pages/hooks/usePages";
import { useIsOnline } from "@/helpers/hooks/use-is-online";
import Heading from "@/components/Heading.vue";

export default defineComponent({
  components: { Page, DisplayImage, Heading },
  setup() {
    const store = useStore();
    const pages = usePages();

    const isOnline = useIsOnline();

    const session = computed(() => store.state.user.session);

    const [, categories] = asyncComputed(async () => {
      if (!session.value || !isOnline.value) return "EXIT";
      const result = await session.value.recommendations.music();
      return result.categories;
    });

    return {
      categories,
      getMidItem,
      isOnline,
      play: (item: Recommendation) => {
        pages.goToPage("Player");
        if (item.type === "VIDEO") {
          store.commit("setQueue", [item.value]);
        } else {
          store.dispatch('playDynamicPlaylist', {
            list: item.value.list, code: item.value.code,
          });
        }
      },
    };
  },
});
