
import { computed, defineComponent, inject } from "vue";
import { MenuAPIKey, MenuAPIKeyDefaultValue } from "../providers/Menu.provider";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const api = inject(MenuAPIKey, MenuAPIKeyDefaultValue);
    const index = api.register(props.value, props.name);

    const position = computed(() => api.calcPosition(index, api.total.value));

    const isSelected = computed(() => api.currentItem.value === index);

    return {
      position,
      isSelected,
    };
  },
});
